<template>
  <FormCard
    :showSaveButton="showEditOrders"
    :title="'Pedido: #' + order.id"
    @save="validate"
    @cancel="$router.push({ name: 'orders' })"
  >
    <v-form
      class="px-5 mt-5"
      ref="form"
      v-model="isValid"
    >
      <v-tabs v-model="currentTab">
        <v-tabs-slider color="primary" />

        <v-tab
          v-for="(item, idx) in tabsItems"
          :key="idx"
          :disabled="
            (item.component === 'invoicing' && !showOrderInvoicingTab) ||
            (item.component === 'observations' && !showOrderObservationsTab)
          "
        >
          {{ item.title }}
        </v-tab>

        <v-tabs-items v-model="currentTab">
          <v-tab-item
            v-for="(item, idx) in tabsItems"
            :key="idx"
            class="py-10"
          >
            <component
              @invoiced="$emit('invoiced')"
              @setReceipts="setOrderReceipts"
              :order="order"
              :is="item.component"
              :shippings="shippings"
              :paymentForms="paymentForms"
              :parkings="parkings"
              :operation_natures="operation_natures"
              :tags="tags"
              :types="types"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-form>

    <v-dialog
      v-model="showWarningModal"
      max-width="450"
      persistent
    >
      <v-card class="pa-5">
        <v-card-title class="headline text-center">
          <v-icon
            color="warning"
            class="mr-2"
            >fas fa-exclamation-triangle</v-icon
          >
          Atenção
        </v-card-title>
        <v-card-text class="text-center">
          <div
            v-html="warningMessage"
            class="text-left"
          ></div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="showWarningModal = false"
          >
            <v-icon left>fas fa-check</v-icon> OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </FormCard>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    FormCard: () => import('@/components/FormCard'),
    SectionTitle: () => import('@/components/SectionTitle'),
    Budget: () => import('./form-tabs/Budget'),
    DeliveryAndPayment: () => import('./form-tabs/DeliveryAndPayment'),
    Invoicing: () => import('./form-tabs/Invoicing'),
    Observations: () => import('./form-tabs/Observations'),
  },
  props: {
    valid: Boolean,
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isValid: null,
    currentTab: null,
    tabsItems: [
      { title: 'Dados do Orçamento', component: 'budget' },
      { title: 'Entrega e pagamento', component: 'delivery-and-payment' },
      { title: 'Faturamento', component: 'invoicing' },
      { title: 'Observações', component: 'observations' },
    ],
    settings: null,
    shippings: null,
    paymentForms: null,
    parkings: null,
    operation_natures: null,
    tags: null,
    types: null,
    profile: null,
    showWarningModal: false,
    warningMessage: '',
  }),
  computed: {
    titleOrder() {
      return this.order ? this.order.id : ''
    },
    ...mapGetters('comercial', [
      'showEditOrders',
      'showOrderInvoicingTab',
      'showOrderObservationsTab',
    ]),
  },
  methods: {
    validate() {
      let formValid = this.$refs.form.validate()
      const missingFields = []
      if (!this.order.print_name) {
        missingFields.push('Arte para Gravação (Nome para arte)')
      }
      if (!this.order.delivery_date) {
        missingFields.push('Data de Entrega (Em Entrega e Pagamento)')
      }
      if (!this.order.print_tag) {
        missingFields.push('Etiqueta')
      }
      if (missingFields.length > 0) {
        this.warningMessage = `
          <p>Os seguintes campos são <strong>obrigatórios</strong> e precisam ser preenchidos:</p>
          <ul>
            ${missingFields
              .map((field) => `<li><strong>${field}</strong></li>`)
              .join('')}
          </ul>
        `
        this.showWarningModal = true
        return
      }
      if (formValid) {
        this.$emit('save')
      }
    },
    setOrderReceipts(receipts) {
      this.$emit('setOrderReceipts', receipts)
    },
    getAllInfo() {
      this.$api
        .get('orders/get_info')
        .then((res) => {
          this.settings = res.data.settings
          this.shippings = res.data.shippings
          this.paymentForms = res.data.paymentForms
          this.operation_natures = res.data.operation_natures
          this.parkings = res.data.parkings
          this.tags = res.data.tags
          this.types = res.data.types
          this.settingValues()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    settingValues() {
      if (
        this.order.observation_order === '' ||
        this.order.observation_order === null
      ) {
        this.order.observation_order = this.settings.obs_order
      }
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') === 'user' && this.type === 'edit') {
      if (this.$store.state.profile.length === 0) {
        this.getUserProfile()
      }
    }
    this.getAllInfo()
  },
}
</script>
